import React from 'react';
import styled from 'styled-components';
import Styles from './RegisterSuccess.styles';

import PrimaryButton from '../../components/PrimaryButton';

import SuccessIcon from "../../icons/register-success.png";

const Container = styled.div`${Styles.container}`;
const SubContainer = styled.div`${Styles.subcontainer}`;
const Title = styled.h2`${Styles.title}`;
const Text = styled.span`${Styles.text}`;
const Icon = styled.img`${Styles.icon}`;
const DatosUsuario = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

`;
const DatosCita = styled.div`
    margin-top: 1.5em;
    margin-bottom: 3em;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 2em;
`;
const Buttons = styled.div`
    display: flex;
    > * {
        height: 2.5em;
        margin-right: 1.5em;
    }
    margin-bottom: 3em;
`;

const TextLine = styled.span`
    color: #676D72;
    margin-top: 0.5em;
    font-size: 1.25em;
`;
const TextNormal = styled.span`
`;
const TextBold = styled.span`
    font-weight: bold;
`;

const RadioOption = styled.input.attrs({
    type: 'radio'
})`${Styles.radio}`;

const RegisterSuccess = ({CURP, nombre, diaCita, lugarCita, domicilioCita, uuid,  numDosis}) => {

    return (<Container>
        <Icon src={SuccessIcon} alt="Icono registro exitoso" />
        <SubContainer>
            <Title>Registro Exitoso</Title>
            <Text>Gracias por hacerle frente a la pandemia.</Text>
            <DatosUsuario>
                <TextLine><TextNormal>CURP: </TextNormal><TextBold>{CURP}</TextBold></TextLine>
                <TextLine><TextNormal>Nombre: </TextNormal><TextBold>{nombre}</TextBold></TextLine>
                <div style={{marginTop:"10px", fontSize:"1.25em"}}>
                    <label style={{color:"#4597EA"}}><RadioOption  value={numDosis} readOnly checked={true} />{numDosis == 1 ? "1ra dosis" : "2da dosis" } </label>
                </div>
            </DatosUsuario>
            <DatosCita>
                <TextLine><TextBold>De acuerdo a tu perfil y la información de tu registro se te ha asignado
                    tu cita el dia: {diaCita} en {lugarCita}, con dirección en {domicilioCita}</TextBold></TextLine>
            </DatosCita>
            <Buttons>
                <PrimaryButton disabled={false} onClick={()=>{window.open(`${process.env.REACT_APP_API_URL}/registro/${uuid}/getPdf`, "_blank")}}>Descargar Cita</PrimaryButton>
            </Buttons>
        </SubContainer>
    </Container>);
}

export default RegisterSuccess;