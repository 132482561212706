import React, { useEffect, useState } from 'react'
import AuthHeader from '../layout/AuthHeader'
import AuthUser from '../layout/AuthUser'
import SponsorsLogos from '../layout/SponsorsLogos'
import RegisterSuccess from '../layout/RegisterSuccess'
import Footer from '../layout/Footer'
function Success () {
  const [cita, setCita] = useState({})
  useEffect(() => {
    // const citaData = window.localStorage.getItem('cita');
    const citaData = JSON.stringify({
      curp: 'RUCD981130HJCVRN07',
      nombre: 'Daniel',
      fecha_cita: 'Miércoles 28 de Abril'
    })
    if (!citaData) {
      alert('Debe ingresar su CURP primero')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
    try {
      const localCita = JSON.parse(citaData)
      setCita(localCita)
    } catch (ex) {
      window.localStorage.removeItem('user')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
  }, [])

  const [user, setUser] = useState({})
  useEffect(() => {
    const userData = window.localStorage.getItem('user')
    if (!userData) {
      alert('Debe ingresar su CURP primero')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
    try {
      const localUser = JSON.parse(userData)
      setUser(localUser)
    } catch (ex) {
      window.localStorage.removeItem('user')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
  }, [])

  return (
    (cita.curp && (
      <div className='App-header' style={{ minHeight: '100vh' }}>
        <AuthHeader curp={user.curp} />
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: '95px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '80%'
            }}
          >
            <AuthUser CURP={user.curp} />
            <RegisterSuccess
              CURP={cita.curp}
              nombre={cita.nombre}
              diaCita={cita.fecha_cita}
              lugarCita={cita.modulo_nombre}
              domicilioCita={cita.modulo_direccion}
              uuid={cita.uuid}
              numDosis={cita.numDosis}
            />
          </div>
          <SponsorsLogos color={'white'} />
          <Footer color={'#00326C'} />
        </div>
      </div>
    )) || <></>
  )
}

export default Success
